window.addEventListener('DOMContentLoaded', (event) => {
    let phoneInputs = document.querySelectorAll('input.js-phone');

    phoneInputs.forEach(function (phoneInput) {
        phoneInput.placeholder = window.phone.placeholder;

        IMask(phoneInput, {
            mask: window.phone.mask
        });
    });

    if ($('.splide').length) {
        var homeCategoriesSlider = new Splide('.splide', {
            type: 'loop',
            perPage: 4,
            autoplay: true,
            gap: '10px',
            breakpoints: {
                640: {
                    perPage: 2,
                },
            }
        });

        homeCategoriesSlider.mount();
    }
});


$(document).on('click', '.js-quantity-block .minus', function () {
    let $qtyInput = $(this).parent().find('input.qty');
    let oldQty = parseInt($qtyInput.val());

    if (oldQty > 1) {
        let newQty = --oldQty;
        $qtyInput.val(newQty);
        $qtyInput.attr('value', newQty)
        $qtyInput.trigger('change');
    }
});

$(document).on('click', '.js-quantity-block .plus', function () {
    let $qtyInput = $(this).parent().find('input.qty');
    let oldQty = parseInt($qtyInput.val());

    if (oldQty < 100) {
        let newQty = ++oldQty;
        $qtyInput.val(newQty);
        $qtyInput.attr('value', newQty)
        $qtyInput.trigger('change');
    }
});

$(document).on('change', '.js-category-sort', function () {
    let $select = $(this);
    let sort = $select.val();
    window.location.replace(window.location.origin + window.location.pathname + '?sort=' + sort);
});

$(document).on('click', '.js-add-to-cart', function() {
    let $btn = $(this);
    let $cartIcon = $btn.find('.js-cart-icon')
    let productId = $btn.data('id');
    let quantity = 1;
    let $cartCount = $('.js-cart-count');
    let $quantityInput = $('input.js-quantity');

    if ($quantityInput.length) {
        quantity = parseInt($quantityInput.val())
    }

    $.ajax({
        method: 'POST',
        url: window.paths.cart.add,
        data: {
            id: productId,
            quantity: quantity
        },
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function( xhr ) {
            $cartIcon.removeClass($cartIcon.data('icon'));
            $cartIcon.addClass('js-rotate ' + $cartIcon.data('loader'));
        },
    })
    .done(function (data) {
        if (data.notify) {
            $.notify({
                message: data.notify,
            }, {
                template: '<div data-notify="container" class="bootstrap-notify col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                    '<button type="button" aria-hidden="true" class="btn-close" data-notify="dismiss"></button>' +
                    '<span data-notify="icon"></span> ' +
                    '<span data-notify="title">{1}</span> ' +
                    '<span data-notify="message">{2}</span>' +
                    '<div class="progress" data-notify="progressbar">' +
                    '<div class="p-progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                    '</div>' +
                    '<a href="{3}" target="{4}" data-notify="url"></a>' +
                    '</div>',
                type: "success",
                delay: 1000,
                offset: {
                    x: 35,
                    y: 105
                },
            });
        }

        if ($cartCount.length) {
            $cartCount.each(function(){
                let previousCount = parseInt($(this).text());
                $(this).show();
                $(this).text(previousCount + quantity);
            })
        }
    })
    .fail(function () {
    })
    .always(function () {
        $cartIcon.removeClass('js-rotate ' + $cartIcon.data('loader'));
        $cartIcon.addClass($cartIcon.data('icon'));
    });
});


$(document).on('click', '.js-remove-from-cart', function() {
    let $btn = $(this);
    let productId = $btn.data('id');
    let quantity = 1;
    let $cartCount = $('.js-cart-count');

    if ($cartCount.length) {
        $cartCount.each(function(){
            let previousCount = parseInt($(this).text());

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                beforeSend: function( xhr ) {
                },
                method: 'POST',
                url: window.paths.cart.remove,
                data: {
                    id: productId
                }
            })
            .done(function () {
                location.reload();
            })
            .fail(function () {
            })
            .always(function () {
                // $cartIcon.removeClass('js-rotate ' + $cartIcon.data('loader'));
                // $cartIcon.addClass($cartIcon.data('icon'));
            });

            $(this).show();
            $(this).text(previousCount + 1);
        })
    }
});


$modalOrderMessage = $('.js-order-request-modal');

if ($modalOrderMessage.length) {
    $modalOrderMessage.on('show.bs.modal', function (e) {
        let $btn = $(e.relatedTarget);
        let orderId = $btn.data('id');

        $modalOrderMessage.find('.js-modal-title > span').text('#' + orderId);
        $modalOrderMessage.find('.js-input-order-id').val(orderId);
    })
}

$(document).on('click', '.js-order-request-btn', function (e) {
    e.preventDefault();

    let $form = $('.js-order-request-form');

    if ($form.length) {
        $form.submit();
    }
})

$(document).on('submit', '.js-newsletter-form', function (e) {
    e.preventDefault();

    $form = $(this);

    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        method: 'POST',
        url: $form.attr('action'),
        data: $form.serialize()
    })
    .done(function () {
        $form[0].reset();
    })
    .fail(function () {
    })
    .always(function () {
    });
})
