window.addEventListener('DOMContentLoaded', (event) => {
    window.validationClearOnFieldChange = () => {
        $('select').on('change', function () {
            $(this).parent('.js-field-invalid').removeClass('js-field-invalid');
        });

        $('input').on('input', function () {
            $(this).parent('.js-field-invalid').removeClass('js-field-invalid');
        });
    }

    window.validationClearErrors = () => {
        let $fieldErrors = $('.js-field-invalid');

        $fieldErrors.each(function() {
            $(this).removeClass('js-field-invalid');
        });
    }

    window.validationHandleErrors = (data) => {
        $.each(data.errors, function(fieldKey, errors) {
            let fieldSelectorKey = fieldKey.replace('_', '-').replace('.', '-')
            console.log('fieldSelectorKey', fieldSelectorKey);

            let $field = $('.js-field-' + fieldSelectorKey);
            let errorWrapperSelector = '.js-field-error-' + fieldSelectorKey;
            let $errorWrapper = $(errorWrapperSelector);

            if (!$field.length) {
                let nameParts = fieldKey.split('.');
                let inputName;

                nameParts.forEach(function(item, index) {
                    if (index === 0) {
                        inputName = item;
                    } else {
                        inputName += '[' + item + ']';
                    }
                });

                $field = $('[name="' + inputName + '"]');
            }

            if ($field.length) {
                if ($field.hasClass('choices__input')) {
                    $field = $field.parent().parent();
                }

                if (!$errorWrapper.length) {
                    $field.after('<span class="js-field-error js-field-error-' + fieldSelectorKey + '"></span>');
                    $errorWrapper = $(errorWrapperSelector);
                }

                $field.parent().addClass('js-field-invalid');
                $errorWrapper.text(errors.join(', '));
            }
        });
    }
})
