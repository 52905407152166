import $ from 'jquery';
window.jQuery = window.$ = $;
import Choices from 'choices.js';
window.Choices = Choices;
import IMask from 'imask';
window.IMask = IMask;
import Splide from '@splidejs/splide';
window.Splide = Splide;

require('jquery.easing');

require('./plugins')
require('./functions')

window.$ = window.jQuery;

require('./helpers')
require('./validation')
require('./custom')
